import CssBaseline from "@material-ui/core/CssBaseline";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import "@styles/slick/main.scss";
import "@styles/slick/theme.scss";
import theme from "@styles/theme/main";
import React from "react";
import "react-sortable-tree/style.css";

const generateClassName = createGenerateClassName({
  productionPrefix: "bgf-",
});

function MyApp({ Component, pageProps }) {
  const [key, setKey] = React.useState(0);

  React.useEffect(() => {
    setKey(1);
  }, []);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <StylesProvider key={key} generateClassName={generateClassName}>
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Component {...pageProps} />
        </ThemeProvider>
      </React.Fragment>
    </StylesProvider>
  );
}

export default MyApp;
