import { createTheme } from "@material-ui/core/styles";
declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    tertiary: Palette["primary"];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions["primary"];
  }
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    // type: "dark",
    primary: {
      main: "#ff6c00",
    },
    secondary: {
      main: "#323e48",
    },
    tertiary: {
      main: "#7b868c",
    },
  },
});

export default theme;
